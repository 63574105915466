<template>
  <div>
    <div class="yue">现金余额</div>
    <h2>{{ nums }}</h2>
    <div class="tixian">
      <el-form
        label-width="80px"
        :model="ruleForms"
        :rules="rules"
        ref="ruleForms"
      >
        <el-form-item label="提现金额" prop="num">
          <div style="width: 300px">
            <el-input
              placeholder="请输入提现金额"
              v-model="ruleForms.num"
              class="addInput"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realname">
          <div style="width: 300px">
            <el-input
              placeholder="请输入真实姓名"
              v-model="ruleForms.realname"
              class="addInput"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="开户行" prop="loginname">
          <div style="width: 300px">
            <el-input
              type="text"
              placeholder="请设置账号"
              v-model="ruleForms.loginname"
              class="addInput"
            ></el-input>
          </div>
        </el-form-item>

        <el-form-item label="银行卡号" prop="phone">
          <div style="width: 300px">
            <el-input
              type="text"
              maxlength="19"
              placeholder="请输入银行卡号"
              v-model="ruleForms.phone"
              class="addInput"
            ></el-input>
          </div>
        </el-form-item>
        <div style="margin: 0px 0px 42px 60px">
          <el-button
            type="danger"
            @click="userAddTap('ruleForms')"
            style="width: 100px; background: red; border: red"
            >提现申请</el-button
          >
          <el-button
            type="danger"
            @click="FanHui()"
            style="width: 100px; background: red; border: red"
            >返回</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { userAdd, userAddLists, userAddList } from '../../../API/user'
import addImg from "../../../assets/image/upImg.jpg";

import axios from 'axios';
import Qs from 'qs';

export default {
  data () {
    //验证手机号的规则;
    // var checkMobile = (rule, value, cb) => {
    //   // 验证手机号的正则表达式
    //   const regMobile = /^([1-9]{1})(\d{14}|\d{18})$/;
    //   if (regMobile.test(value)) {
    //     return cb();
    //   }
    //   cb(new Error("请输入正确的银行卡号"));
    // };
    var checkMobile =(rule, value, callback) => {
      const strBin = '10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99'
      if (!value) {
        return callback(new Error('收款账户不能为空'))
      } else if (!Number.isInteger(+value)) {
        callback(new Error('银行卡号必须全为数字'))
      } else if (value.trim().length < 12 || value.trim().length > 19) {
        callback(new Error('银行卡号长度必须在12到19之间'))
      } else if (strBin.indexOf(value.substring(0, 2)) === -1) {
        callback(new Error('银行卡号开头6位不符合规范'))
      } else {
        callback()
      }
    };

    return {
      ruleForms: {
        num: '',// 金额
        loginname: '',
        headImg: "", // 头像
        headImgUrl: addImg, // 回显地址
        realname: '',
        sex: "男",
        zhiwu: '',
        zhiji: '',
        imageUrl: '',//头像url
        phone: '', // 手机号
        time: '',// 入职时间
        textarea: '',// 备注
      },

      nums: '',

      rules: {
        num: [
          { required: true, message: '请输入提现金额', trigger: 'blur' }
        ],
        loginname: [
          { required: true, message: '请输入开户行', trigger: 'blur' }
        ],
        realname: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '银行卡号不能为空' },
          {
            validator: checkMobile,
            // message: "请输入正确的银行卡号",
            trigger: "blur"
          }
        ],
      },


    }
  },

  mounted () {

    this.nums = this.$route.query.nums
    // this.CustomView();
    // this.setRank();

  },
  methods: {
    FanHui () {
      this.$router.push('/Pending/countbalance/?id=4')
    },
    //提交提现申请
    userAddTap (ruleForms) {
      this.$refs[ruleForms].validate((valid, obj) => {
        if (valid) {

          let id = localStorage.getItem('ZZId')
          var formdata = new FormData()
          formdata.append('userId', id);
          formdata.append('loginname', this.ruleForms.loginname);//账号
          formdata.append('realname', this.ruleForms.realname);
          formdata.append('num', this.ruleForms.num);
          formdata.append('phone', this.ruleForms.phone);
          //添加
          this.$axios({
            method: "post",
            url: "/author/authorWithdraw",
            data:{
              userId:id,
              withdrawName:this.ruleForms.realname,
              num:this.ruleForms.num,
              withdrawAccount:this.ruleForms.phone,
              withdrawBank:this.ruleForms.loginname
            },
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          }).then(res => {
            if (res.data.code == 0) {
              this.$message({
             //   type: 'info',
                message: '提交成功',
                type: 'success'
              });
              this.$router.push("/Pending/countbalance")
            } else {
              this.$message(res.data.msg);
            }

          })
        }
      })
    },
    sexs (e) {
      console.log(e);
    },
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.headerImg {
  object-fit: cover;
  object-position: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 50px 150px;
}
.headerInput {
  position: absolute;
  top: 220px;
  left: 43%;
  right: 0;
  width: 8%;
  height: 35%;
  border-radius: 50%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.photo {
  width: 150px !important;
  height: 150px !important;
}
.tixian {
  margin-top: 30px;
}
</style>